import type {
	BlogStoryblok,
	PageStoryblok,
	PreparationStoryblok,
	StoreStoryblok,
	VacancyStoryblok
} from "~/types/storyblok";

export const useStoryblokSeo = (
	blok: PageStoryblok | StoreStoryblok | PreparationStoryblok | BlogStoryblok | VacancyStoryblok
) => {
	const seoData = blok.seo;
	// This works as follows; fetch data from Storyblok, overwrite if data has been configured.
	// For the twitter:title for example it'll follow the twitter:title -> og:title -> storyblok title -> app.vue title data-flow.
	useSeoMeta({
		...(seoData?.title && { title: seoData.title }),
		...(seoData?.description && { description: seoData.description }),
		...((seoData?.og_title || seoData?.title) && { ogTitle: `Kippie.nl - ${seoData.og_title || seoData.title}` }),
		...((seoData?.og_description || seoData?.description) && {
			ogDescription: seoData?.og_description || seoData.description
		}),
		...(seoData?.og_image && { ogImage: seoData.og_image }),
		...((seoData?.twitter_title || seoData?.og_title || seoData?.title) && {
			twitterTitle: `Kippie.nl - ${seoData.twitter_title || seoData.og_title || seoData.title}`
		}),
		...((seoData?.twitter_description || seoData?.og_description || seoData?.description) && {
			twitterDescription: seoData.twitter_description || seoData.og_description || seoData.description
		}),
		...((seoData?.twitter_image || seoData?.og_image) && {
			twitterImage: seoData.twitter_image || seoData.og_image
		})
	});
};
